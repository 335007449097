import React, { useState, useEffect } from "react";
import { Form, Button, Table, notification, Select, Row, Col } from "antd";
import { getApi } from "../../utils/getApiMethod";
import { FilterOutlined } from "@ant-design/icons";
import { FaRegFilePdf } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";

const { Option } = Select;

const Report = () => {
  const [year, setYear] = useState("");
  const [fromMonth, setFromMonth] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [clientNames, setClientNames] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [results, setResults] = useState({});
  const [reportFilterForm] = Form.useForm();

  // Fetch the client list
  const getClientsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        setAllClients(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "client";
    getClientsList(url);
  }, []);

  const handleNotification = (message) => {
    notification.error({
      message: "Error",
      description: message,
      placement: "topRight",
    });
  };

  const handleSubmit = async () => {
    // Validate inputs
    if (
      !year ||
      !fromMonth ||
      !toMonth ||
      clientNames.length === 0 ||
      !filterType
    ) {
      handleNotification("Please fill in all fields.");
      return;
    }

    const from = parseInt(fromMonth);
    const to = parseInt(toMonth);
    if (isNaN(from) || isNaN(to) || from < 1 || to > 12 || from > to) {
      handleNotification(
        "Please enter valid months (1-12) and ensure fromMonth is less than or equal to toMonth."
      );
      return;
    }

    try {
      const response = await getApi(
        `monthlywisebookings?year=${year}&frommonth=${from}&tomonth=${to}&filter_type=${filterType}&${clientNames
          .map((name) => `clientnames=${name}`)
          .join("&")}`
      );
      setResults(response);
    } catch (error) {
      handleNotification("Failed to fetch data: " + error.message);
    }
  };

  const baseColumns = [
    {
      title: "Client Name",
      dataIndex: "clientname",
      key: "clientname",
    },
    {
      title: "Report Description",
      dataIndex: "reportdesc",
      key: "reportdesc",
    },
  ];

  const monthColumns = [];
  const monthNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  const from = parseInt(fromMonth);
  const to = parseInt(toMonth);
  if (!isNaN(from) && !isNaN(to) && from <= to) {
    for (let index = from; index <= to; index++) {
      const monthName = monthNames[index - 1];
      monthColumns.push({
        title: monthName.charAt(0).toUpperCase() + monthName.slice(1),
        dataIndex: monthName,
        key: monthName,
      });
    }
  }

  const columns = [...baseColumns, ...monthColumns];

  const dataSource = Object.values(results).map((item) => ({
    clientname: item.clientname,
    reportdesc: item.reportdesc,
    ...Object.fromEntries(
      monthColumns.map((col) => [col.dataIndex, item[col.dataIndex] || "0"])
    ),
    key: item.clientname,
  }));

  const handleReset = () => {
    // Reset the form fields
    reportFilterForm.resetFields();
    setYear("");
    setFromMonth("");
    setToMonth("");
    setClientNames([]);
    setFilterType("");
    setResults({}); // Clear results if needed
  };

  const handleSelectChange = (selected) => {
    if (selected.includes("all")) {
      setClientNames(allClients.map((client) => client.clientname));
    } else {
      setClientNames(selected);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    const pageMargin = 8;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    doc.setLineWidth(0.1);
    doc.setDrawColor(0, 0, 0);
    doc.rect(
      pageMargin,
      pageMargin,
      pageWidth - 2 * pageMargin,
      pageHeight - 2 * pageMargin
    );

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Client Report", pageMargin + 5, pageMargin + 5);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text(
      `Report Type - ${filterType.toUpperCase()} `,
      pageMargin + 5,
      pageMargin + 15
    );

    // Extract headers and data for the table
    const headers = [
      "Client Name",
      "Report Description",
      ...monthColumns.map((col) => col.title),
    ];
    const data = dataSource.map((item) => [
      item.clientname,
      item.reportdesc,
      ...monthColumns.map((col) => item[col.dataIndex]),
    ]);

    // Create Table with jsPDF Autotable
    doc.autoTable({
      head: [headers],
      body: data,
      startY: pageMargin + 20,
      theme: "striped",
      styles: {
        font: "helvetica",
        fontSize: 8,
        cellPadding: 3,
        textColor: [0, 0, 0],
        halign: "center",
      },
      headStyles: {
        fillColor: [0, 123, 255],
        textColor: [255, 255, 255],
        fontStyle: "bold",
        fontSize: 7,
      },
    });
    doc.save("client_report.pdf");
  };

  return (
    <div className="flex p-2" style={{ height: "100vh" }}>
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Sticky Header */}
        <div className="py-3 px-1 flex items-center justify-between rounded-md sticky top-0 z-10 bg-white">
          <h2 className="com-heading">Booking Report</h2>
        </div>

        {/* Filter Form */}
        <Form
          form={reportFilterForm}
          layout="inline"
          onFinish={handleSubmit}
          style={{
            marginBottom: "20px",
            padding: "20px",
            border: "1px solid #f0f0f0",
            borderRadius: "8px",
            backgroundColor: "#fafafa",
          }}
        >
          <Row gutter={4}>
            <Col>
              <Form.Item name="year">
                <Select
                  style={{ width: 110 }}
                  placeholder="Year"
                  value={year} // Ensure this is undefined or null initially
                  onChange={setYear}
                >
                  {[2022, 2023, 2024, 2025].map((y) => (
                    <Option key={y} value={y}>
                      {y}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="fromMonth">
                <Select
                  style={{ width: 100 }}
                  placeholder="From Month"
                  value={fromMonth} // Ensure this is undefined or null initially
                  onChange={setFromMonth}
                >
                  {Array.from({ length: 12 }, (_, index) => (
                    <Option key={index + 1} value={index + 1}>
                      {index + 1}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="toMonth">
                <Select
                  style={{ width: 100 }}
                  placeholder="To Month"
                  value={toMonth} // Ensure this is undefined or null initially
                  onChange={setToMonth}
                >
                  {Array.from({ length: 12 }, (_, index) => (
                    <Option key={index + 1} value={index + 1}>
                      {index + 1}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="clientNames">
                <Select
                  mode="multiple"
                  style={{ width: 150 }}
                  placeholder="client names"
                  maxTagCount="responsive"
                  // onChange={setClientNames}
                  onChange={handleSelectChange}
                >
                  <Option key="all" value="all">
                    Select All
                  </Option>
                  {allClients.map((client) => (
                    <Option key={client.id} value={client.clientname}>
                      {client.clientname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="filterType">
                <Select
                  style={{ width: 130 }}
                  placeholder="Filter Type"
                  value={filterType} // Ensure this is undefined or null initially
                  onChange={setFilterType}
                >
                  <Option value="total">Total</Option>
                  <Option value="amountreceived">Amount Received</Option>
                  <Option value="amountpending">Amount Pending</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="cus-btn"
                  icon={<FilterOutlined className="mr-1" />}
                >
                  Filter
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button onClick={handleReset} icon={<FilterOutlined />}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  className="cus-btn"
                  type="primary"
                  icon={<FaRegFilePdf className="mr-1" />}
                  onClick={generatePDF}
                  disabled={dataSource.length > 0 ? false : true}
                >
                  Export PDF
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {/* Scrollable Table */}
        <div
          className="overflow-y-auto"
          style={{ maxHeight: "calc(100vh - 80px)" }}
        >
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            style={{ marginTop: "20px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Report;

import { Button, notification, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiOutlineUser } from "react-icons/ai";
import { getApi } from "../../utils/getApiMethod";
import { postApi } from "../../utils/postApiMethod";
import MultiFieldFormComponent from "../../components/MultiFieldFormComponents";
import moment from "moment";
import {
  MdOutlineCurrencyRupee,
  MdOutlineReceipt,
  MdPayment,
} from "react-icons/md";
import { BiRupee } from "react-icons/bi";
import { putApi } from "../../utils/putApiMethod";

const schema = yup.object().shape({
  clientname: yup.string().required("Client Name is required"),
  amountreceiving: yup
    .number()
    .typeError("Amount Receiving must be a number")
    .positive("Amount Receiving must be positive")
    .required("Amount Receiving is required"),
  paymentmode: yup.string().required("Payment Mode is required"),
  paymentreference: yup.string().required("Payment Reference is required"),
});

const Payment = () => {
  const [paymentList, setPaymentList] = useState([]);
  const [clientPaymentList, setClientPaymentList] = useState([]);
  const [isPaymentForm, setIsPaymentForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [currentRecord, setCurrentRecord] = useState([]);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      clientname: "",
      totalamount: "",
      // totalstatus: "",
      receivedamount: "",
      // receivedstatus: "",
      pendingamount: "",
      // pendingstatus: "",
      amountreceiving: "",
      paymentmode: "",
      paymentreference: "",
    },
  });
  const [clientsList, setClientsList] = useState([]);

  const getClientsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        // console.log("result", result);
        setClientsList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "client";
    getClientsList(url);
  }, []);

  const clientOptions = clientsList.map((client) => ({
    label: client.clientname,
    value: client.clientname,
    icon: AiOutlineUser,
  }));

  const clientNameValue = watch("clientname");

  // Update the state when clientNameValue changes
  useEffect(() => {
    // console.log("clientNameValue", clientNameValue);
    if (clientNameValue) {
      const matchingRecords = clientPaymentList.filter(
        (payment) => payment.clientname === clientNameValue
      );
      // console.log("matchingRecords", matchingRecords);
      if (matchingRecords.length > 0) {
        const totalAmount = matchingRecords.reduce(
          (acc, record) => acc + parseFloat(record.total),
          0
        );
        const receivedAmount = matchingRecords.reduce(
          (acc, record) => acc + parseFloat(record.amountreceived),
          0
        );
        const pendingAmount = matchingRecords.reduce(
          (acc, record) => acc + parseFloat(record.amountpending),
          0
        );

        reset({
          clientname: clientNameValue,
          totalamount: totalAmount.toString(),
          receivedamount: receivedAmount.toString(),
          pendingamount: pendingAmount.toString(),
          amountreceiving: "",
        });
      } else {
        reset({
          clientname: clientNameValue,
          totalamount: "",
          receivedamount: "",
          pendingamount: "",
          amountreceiving: "",
        });
      }
    }
  }, [clientNameValue, clientPaymentList, reset]);

  const getClientPaymentHist = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        // console.log("resultpayment", result);
        setClientPaymentList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `booking`;
    getClientPaymentHist(url);
  }, []);

  const getPaymentList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        // console.log("resultpayment", result);
        setPaymentList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `booking-payments`;
    getPaymentList(url);
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "clientname",
      key: "clientname",
    },
    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Total Status",
      dataIndex: "totalreceiptstatus",
      key: "totalreceiptstatus",
    },
    {
      title: "Received Amount",
      dataIndex: "amountreceived",
      key: "amountreceived",
    },
    {
      title: "Received Status",
      dataIndex: "amountreceivedstatus",
      key: "amountreceivedstatus",
    },
    {
      title: "Pending Amount",
      dataIndex: "amountpending",
      key: "amountpending",
    },
    {
      title: "Pending Status",
      dataIndex: "amountpendingstatus",
      key: "amountpendingstatus",
    },
  ];

  const handleAddClick = () => {
    reset({
      clientname: "",
      totalamount: "",
      totalstatus: "",
      receivedamount: "",
      receivedstatus: "",
      pendingamount: "",
      pendingstatus: "",
    });
    setIsPaymentForm(true);
  };

  const handleDiscard = () => {
    reset({
      clientname: "",
      totalamount: "",
      totalstatus: "",
      receivedamount: "",
      receivedstatus: "",
      pendingamount: "",
      pendingstatus: "",
    });
    setIsPaymentForm(false);
    if (isEdit) {
      setIsEdit(false);
    }
  };

  const onSubmit = async (data) => {
    // console.log("Connection Data:", data);

    const selClientName = data?.clientname;
    const selectedClient = clientsList.find(
      (client) => client.clientname === selClientName
    );
    const clientId = selectedClient ? selectedClient.clientid : null;

    const formattedDate = moment().format("DD-MM-YYYY");

    const totalamount = parseFloat(data.totalamount || 0);
    const receivedamount = parseFloat(data.receivedamount || 0);
    const receivingamount = parseFloat(data.amountreceiving || 0);

    const pendingpayment = totalamount - receivedamount - receivingamount;
    // console.log("pendingpayment Data:", pendingpayment);
    const payload = {
      clientid: clientId,
      clientname: data.clientname,
      totalamount: data.totalamount.toString(),
      totalstatus: "Receipt",
      receivedamount: data.amountreceiving.toString(),
      receivedstatus: "Invoiced",
      pendingamount: pendingpayment.toString(),
      pendingstatus: "Pending",
      paymentdate: formattedDate,
      paymentmode: data.paymentmode,
      paymentreference: data.paymentreference,
    };
    // console.log("payload:", payload);
    const currpendingamount = parseFloat(data.pendingamount || 0);

    if (receivingamount > currpendingamount) {
      notification.warning({
        message: "Receiving amount cannot exceed pending amount",
      });
      return;
    }

    const url = "payment";
    try {
      setIsLoading(true);
      const response = await postApi(url, payload);
      if (response?.status_code === 200) {
        notification.success({
          message: response.message,
        });

        // Trigger the PUT request for the second API call after the first one is successful
        const updateTicketsUrl = `update-tickets?clientname=${data.clientname}&receivingamount=${data.amountreceiving}`;
        const updateResponse = await putApi(updateTicketsUrl, {});

        if (updateResponse?.status_code === 200) {
          notification.success({
            message: updateResponse.message,
          });
        } else {
          notification.error({
            message: updateResponse.message || "Failed to update tickets.",
          });
        }

        const paymentListUrl = "booking-payments";
        getPaymentList(paymentListUrl);
        setIsPaymentForm(false);
        setIsLoading(false);
        window.location.reload();
      } else {
        notification.error({
          message: response.message || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const amountreceivingValue = watch("amountreceiving");

  useEffect(() => {
    // console.log("amountreceivingValue", amountreceivingValue);

    // Check if the amountreceivingValue is an empty string first
    if (amountreceivingValue === "") {
      setValue("paymentmode", "");
      setValue("paymentreference", "");
    } else {
      const parsedamountreceivingValue = parseFloat(amountreceivingValue) || 0;

      // Check if the parsed value is 0
      if (parsedamountreceivingValue === 0) {
        setValue("paymentmode", "N/A");
        setValue("paymentreference", "N/A");
      } else {
        setValue("paymentmode", "");
        setValue("paymentreference", "");
      }
    }

  }, [amountreceivingValue, setValue]);

  const handleNameClick = (record) => {
    // console.log("Resetting form with record data:", record);
    setIsPaymentForm(true);
    // setCurrentRecord(record);
    setIsEdit(true);
    reset({
      clientname: record.clientname,
      totalamount: record.totalamount,
      totalstatus: record.totalstatus,
      receivedamount: record.receivedamount,
      receivedstatus: record.receivedstatus,
      pendingamount: record.pendingamount,
      pendingstatus: record.pendingstatus,
    });
  };

  const formFields = [
    {
      name: "clientname",
      label: "Client Name",
      type: "select",
      icon: AiOutlineUser,
      options: clientOptions,
      editable: true,
    },
    {
      name: "totalamount",
      label: "Total Amount",
      type: "input",
      icon: MdOutlineCurrencyRupee,
      editable: false,
    },
    {
      name: "pendingamount",
      label: "Pending Amount",
      type: "input",
      icon: MdOutlineCurrencyRupee,
      editable: false,
    },
    {
      name: "receivedamount",
      label: "Amount Received",
      type: "input",
      icon: BiRupee,
      editable: false,
    },
    {
      name: "amountreceiving",
      label: "Amount Receiving",
      type: "input",
      icon: MdOutlineCurrencyRupee,
      editable: true,
    },
    {
      name: "paymentmode",
      label: "Payment Mode",
      type: "input",
      icon: MdPayment,
      editable: true,
    },
    {
      name: "paymentreference",
      label: "Payment Reference",
      type: "input",
      icon: MdOutlineReceipt,
      editable: true,
    },
  ];

  return (
    <>
      {isPaymentForm ? (
        <div className="flex space-x-6 p-2" style={{ height: "100vh" }}>
          <div className="flex-1">
            {/* Sticky header */}
            <div className="p-3 flex items-center justify-between sticky top-0 z-10 bg-white">
              <h2 className="com-heading">{`${
                isEdit ? "Edit" : "Create"
              } Payment Form`}</h2>
              <Button className="cus-btn" onClick={handleDiscard}>
                Discard
              </Button>
            </div>

            {/* Scrollable content */}
            <div
              className="mt-2 p-3 overflow-y-auto bg-[#f8f8f8]"
              style={{ maxHeight: "calc(100vh - 80px)" }}
            >
              <MultiFieldFormComponent
                formFields={formFields}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                buttonLoading={isLoading}
                buttonName={"Save"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex p-2" style={{ height: "100vh" }}>
          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Sticky Header */}
            <div className="py-3 px-1 flex items-center justify-between rounded-md sticky top-0 z-10 bg-white">
              <h2 className="com-heading">Payment List</h2>
              <Button className="cus-btn" onClick={handleAddClick}>
                Add
              </Button>
            </div>

            {/* Scrollable Table */}
            <div
              className="overflow-y-auto"
              style={{ maxHeight: "calc(100vh - 80px)" }}
            >
              <Table
                dataSource={paymentList}
                columns={columns}
                scroll={{ x: "max-content" }}
                pagination={false}
                onRow={(record) => ({
                  onClick: () => handleNameClick(record),
                })}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Payment;

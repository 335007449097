import React, { useState, useEffect } from "react";
import { Button, Card, Col, DatePicker, Form, Row, Typography } from "antd";
import { getApi } from "../../utils/getApiMethod";
import moment from "moment";
import { FilterOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const Home = () => {
  const [bookingList, setBookingList] = useState([]);
  const [clientsList, setClientsList] = useState([]);

  const getBookingsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        setBookingList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getClientsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        setClientsList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const bookingUrl = "booking";
    const clientUrl = "client";
    getBookingsList(bookingUrl);
    getClientsList(clientUrl);
  }, []);

  const calculateTotals = () => {
    let totalClients = new Set();
    let totalValue = 0;
    let totalPending = 0;
    let totalInvoiced = 0;

    bookingList.forEach((booking) => {
      totalClients.add(booking.clientname);

      // Add total value
      totalValue += parseFloat(booking.total);

      // Add pending amount
      if (booking.amountpendingstatus === "pending") {
        totalPending += parseFloat(booking.amountpending);
      }

      // Add invoiced amount
      if (booking.amountreceivedstatus === "invoiced") {
        totalInvoiced += parseFloat(booking.amountreceived);
      }
    });

    return {
      totalClients: totalClients.size,
      totalValue,
      totalPending,
      totalInvoiced,
    };
  };

  const totals = calculateTotals();

  const [bookingFilterForm] = Form.useForm();
  const [bookingDateValue, setBookingDateValue] = useState(null);

  const handleBookingDateChange = (date, dateString) => {
    // Check if the date is valid
    if (date && date.isValid()) {
      setBookingDateValue(dateString);
    } else {
      console.warn("Invalid date selected");
    }
  };

  const handleFilterSubmit = (values) => {
    // console.log("Form values before submission:", values);
    const columns = [];
    const valuesArray = [];

    if (values.bookingdate) {
      columns.push("bookingdate");
      valuesArray.push(bookingDateValue);
    }

    if (columns.length > 0 && valuesArray.length > 0) {
      const columnsString = columns.join(",");
      const valuesString = valuesArray.join(",");
      const url = `filterbooking?columns=${encodeURIComponent(
        columnsString
      )}&values=${encodeURIComponent(valuesString)}`;

      // console.log("Generated URL:", url);
      getBookingsList(url);
    } else {
      const url = "booking";
      // console.log("URL without params:", url);
      getBookingsList(url);
    }
  };

  const handleReset = () => {
    bookingFilterForm.resetFields();
    const url = "booking";
    getBookingsList(url);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Title level={4} style={{ textAlign: "center", marginBottom: "30px" }}>
        Booking Summary
      </Title>
      <Form
        form={bookingFilterForm}
        layout="inline"
        onFinish={handleFilterSubmit}
        style={{
          marginBottom: "20px",
          padding: "20px",
          border: "1px solid #f0f0f0",
          borderRadius: "8px",
          backgroundColor: "#fafafa",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Row gutter={16}>
          <Col>
            <Form.Item
              // label="Booking Date"
              name="bookingdate"
            >
              <DatePicker
                value={
                  bookingDateValue
                    ? moment(bookingDateValue, "YYYY-MM-DD")
                    : null
                }
                onChange={handleBookingDateChange}
                placeholder="Select Booking date"
                format="YYYY-MM-DD"
                style={{ width: "180px" }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                className="cus-btn"
                icon={<FilterOutlined className="mr-1" />}
              >
                Filter
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button onClick={handleReset} icon={<FilterOutlined />}>
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={6}>
          <Card
            hoverable
            bordered={false}
            style={{
              backgroundColor: "#f0f2f5",
              textAlign: "center",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <Title level={4} style={{ marginBottom: "10px" }}>
              Total Clients
            </Title>
            <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
              {clientsList.length}
            </Text>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card
            hoverable
            bordered={false}
            style={{
              backgroundColor: "#e6f7ff",
              textAlign: "center",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <Title level={4} style={{ marginBottom: "10px" }}>
              Total Value
            </Title>
            <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
              {/* ₹{totals.totalValue.toFixed(2)} */}
              ₹{new Intl.NumberFormat('en-IN').format(totals.totalValue.toFixed(2))}
            </Text>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card
            hoverable
            bordered={false}
            style={{
              backgroundColor: "#fff7e6",
              textAlign: "center",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <Title level={4} style={{ marginBottom: "10px" }}>
              Total Pending
            </Title>
            <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
              {/* ₹{totals.totalPending.toFixed(2)} */}
              ₹{new Intl.NumberFormat('en-IN').format(totals.totalPending.toFixed(2))}
            </Text>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card
            hoverable
            bordered={false}
            style={{
              backgroundColor: "#f6ffed",
              textAlign: "center",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <Title level={4} style={{ marginBottom: "10px" }}>
              Total Invoiced
            </Title>
            <Text style={{ fontSize: "24px", fontWeight: "bold" }}>
              {/* ₹{totals.totalInvoiced.toFixed(2)} */}
              ₹{new Intl.NumberFormat('en-IN').format(totals.totalInvoiced.toFixed(2))}
            </Text>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Home;

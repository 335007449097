import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getApi } from "../../utils/getApiMethod";
import { postApi } from "../../utils/postApiMethod";
import { putApi } from "../../utils/putApiMethod";
import moment from "moment";
import {
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineNumber,
  AiOutlineClockCircle,
  AiOutlineFileText,
  AiOutlineTag,
  AiOutlineTeam,
  // AiOutlineCheckCircle,
  AiOutlineIdcard,
} from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import MultiFieldFormComponent from "../../components/MultiFieldFormComponents";
import { FilterOutlined } from "@ant-design/icons";
import { MdOutlineReceipt, MdPayment } from "react-icons/md";

const schema = yup.object().shape({
  bookingdate: yup.string().required("Booking Date is required"),
  clientname: yup.string().required("Client Name is required"),
  passengername: yup.string().required("Passenger name is required"),
  noofpeople: yup
    .number()
    .required("Number of People is required")
    .positive()
    .integer(),
  traveldate: yup.string().required("Travel Date is required"),
  sector: yup.string().required("Sector is required"),
  pnr: yup.string().required("PNR is required"),
  airlines: yup.string().required("Airlines is required"),
  flightnumber: yup.string().required("Flight Number is required"),
  departuretime: yup.string().required("Departure Time is required"),
  arraivaltime: yup.string().required("Arrival Time is required"),
  fare: yup.number().required("Fare is required").positive(),
  fee: yup.number().required("Fee is required").positive(),
  gst: yup.number().required("GST is required").positive(),
  bookinggivenby: yup.string().required("Booking Given By is required"),
  ticketissuedby: yup.string().required("Ticket Issued By is required"),
  bookingportal: yup.string().required("Booking Portal is required"),
  amountreceived: yup.string().required("Amount Received is required"),
  // amountreceivedstatus: yup.string().required("Amount Received Status is required"),
  amountpending: yup.string().required("Amount Pending is required"),
  // amountpendingstatus: yup.string().required("Amount Pending Status is required"),
  // ticketstatus: yup.string().required("Ticket Status is required"),
  amountreceiving: yup.string().required("Amount Receiving is required"),
  paymentmode: yup.string().required("Payment Mode is required"),
  paymentreference: yup.string().required("Payment Reference is required"),
});

const Ticketing = () => {
  const [clientsList, setClientsList] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [isClientForm, setIsClientForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      bookingdate: "",
      clientname: "",
      passengername: "",
      noofpeople: "",
      traveldate: "",
      sector: "",
      pnr: "",
      airlines: "",
      flightnumber: "",
      departuretime: "",
      arraivaltime: "",
      fare: "",
      fee: "",
      gst: "",
      bookinggivenby: "",
      ticketissuedby: "",
      bookingportal: "",
      amountreceived: "",
      amountpending: "",
      // ticketstatus: "",
      amountreceiving: "0",
      paymentmode: "N/A",
      paymentreference: "N/A",
    },
  });

  const getClientsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        // console.log("result", result);
        setClientsList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "client";
    getClientsList(url);
  }, []);

  const clientOptions = clientsList.map((client) => ({
    label: client.clientname,
    value: client.clientname,
    icon: AiOutlineUser,
  }));

  const getBookingsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result) {
        setBookingList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "booking";
    getBookingsList(url);
  }, []);

  const columns = [
    { title: "Booking Id", dataIndex: "bookingid", key: "bookingid" },
    {
      title: "Booking Date",
      dataIndex: "bookingdate",
      key: "bookingdate",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    { title: "Client Name", dataIndex: "clientname", key: "clientname" },
    { title: "Passenger", dataIndex: "passengername", key: "passengername" },
    { title: "Number of People", dataIndex: "noofpeople", key: "noofpeople" },
    { title: "Travel Date", dataIndex: "traveldate", key: "traveldate" },
    { title: "Sector", dataIndex: "sector", key: "sector" },
    { title: "PNR", dataIndex: "pnr", key: "pnr" },
    { title: "Airlines", dataIndex: "airlines", key: "airlines" },
    { title: "Flight Number", dataIndex: "flightnumber", key: "flightnumber" },
    {
      title: "Departure Time",
      dataIndex: "departuretime",
      key: "departuretime",
    },
    { title: "Arrival Time", dataIndex: "arraivaltime", key: "arraivaltime" },
    { title: "Fare", dataIndex: "fare", key: "fare" },
    { title: "Fee", dataIndex: "fee", key: "fee" },
    { title: "GST", dataIndex: "gst", key: "gst" },
    { title: "Total", dataIndex: "total", key: "total" },

    {
      title: "Amount Received",
      dataIndex: "amountreceived",
      key: "amountreceived",
    },
    {
      title: "Amount Pending",
      dataIndex: "amountpending",
      key: "amountpending",
    },
    {
      title: "Ticket Status",
      dataIndex: "ticketstatus",
      key: "ticketstatus",
    },
    {
      title: "Booking Given By",
      dataIndex: "bookinggivenby",
      key: "bookinggivenby",
    },
    {
      title: "Ticket Issued By",
      dataIndex: "ticketissuedby",
      key: "ticketissuedby",
    },
    {
      title: "Booking Portal",
      dataIndex: "bookingportal",
      key: "bookingportal",
    },
  ];

  const handleAddClick = () => {
    reset({
      bookingdate: "",
      clientname: "",
      passengername: "",
      noofpeople: "",
      traveldate: "",
      sector: "",
      pnr: "",
      airlines: "",
      flightnumber: "",
      departuretime: "",
      arraivaltime: "",
      fare: "",
      fee: "",
      gst: "",
      bookinggivenby: "",
      ticketissuedby: "",
      bookingportal: "",
      amountreceived: "0",
      amountpending: "0",
      ticketstatus: "",
      amountreceiving: "0",
      paymentmode: "N/A",
      paymentreference: "N/A",
    });
    setIsClientForm(true);
  };

  const handleDiscard = () => {
    reset({
      bookingdate: "",
      clientname: "",
      passengername: "",
      noofpeople: "",
      traveldate: "",
      sector: "",
      pnr: "",
      airlines: "",
      flightnumber: "",
      departuretime: "",
      arraivaltime: "",
      fare: "",
      fee: "",
      gst: "",
      bookinggivenby: "",
      ticketissuedby: "",
      bookingportal: "",
      amountreceived: "",
      amountpending: "",
      ticketstatus: "",
    });
    setIsClientForm(false);
    setIsEdit(false);
  };

  const fareValue = watch("fare");
  const feeValue = watch("fee");
  const gstValue = watch("gst");

  useEffect(() => {
    if (!isEdit) {
      const fare = parseFloat(fareValue) || 0;
      const fee = parseFloat(feeValue) || 0;
      const gst = parseFloat(gstValue) || 0;

      const total = fare + fee + gst;

      // Set amountpending dynamically
      setValue("amountpending", total.toString());
    }
  }, [fareValue, feeValue, gstValue, setValue, isEdit]);

  const amountreceivingValue = watch("amountreceiving");

  useEffect(() => {
    // console.log("amountreceivingValue", amountreceivingValue);

    // Check if the amountreceivingValue is an empty string first
    if (amountreceivingValue === "") {
      setValue("paymentmode", "");
      setValue("paymentreference", "");
    } else {
      const parsedamountreceivingValue = parseFloat(amountreceivingValue) || 0;

      // Check if the parsed value is 0
      if (parsedamountreceivingValue === 0) {
        setValue("paymentmode", "N/A");
        setValue("paymentreference", "N/A");
      } else {
        setValue("paymentmode", "");
        setValue("paymentreference", "");
      }
    }
  }, [amountreceivingValue, setValue]);

  useEffect(() => {
    if (isEdit) {
      // Set amountpending dynamically
      setValue("amountreceiving", "0");
      setValue("paymentmode", "N/A");
      setValue("paymentreference", "N/A");
    }
  }, [isEdit, setValue]);

  const onSubmit = async (data) => {
    // console.log("Connection Data:", data);

    const amountreceived = parseFloat(data.amountreceived) || 0;
    const amountreceiving = parseFloat(data.amountreceiving) || 0;
    const totalAmountReceived = amountreceived + amountreceiving;

    const fare = parseFloat(data.fare) || 0;
    const fee = parseFloat(data.fee) || 0;
    const gst = parseFloat(data.gst) || 0;
    const total = fare + fee + gst;

    const amountpending = total - totalAmountReceived;

    const pendingamount = parseFloat(data.amountpending || 0);
    const receivingamount = parseFloat(data.amountreceiving || 0);
    // console.log("amountpending Data:", pendingamount);
    // console.log("amountreceiving Data:", receivingamount);
    // console.log("amountpending Data:", amountpending);
    // console.log("total Data:", total);

    let ticketstatus;

    if (totalAmountReceived === 0) {
      ticketstatus = "Open";
    } else if (totalAmountReceived < total) {
      ticketstatus = "Pending";
    } else if (totalAmountReceived === total) {
      ticketstatus = "Closed";
    }
    // console.log("ticketstatus Data:", ticketstatus);

    if (receivingamount > pendingamount) {
      notification.warning({
        message: "Amount Receiving is More than Total",
        description:
          "The amount receiving exceeds the total fare. Please verify the payment amount.",
      });
      return;
    }

    const formatDateToIST = (gmtDate) => {
      const date = new Date(gmtDate);
      return date.toLocaleDateString("en-CA", { timeZone: "Asia/Kolkata" });
    };

    const formatTimeToIST = (gmtDate) => {
      const date = new Date(gmtDate);
      return date.toLocaleTimeString("en-GB", {
        timeZone: "Asia/Kolkata",
        hour12: false,
      });
    };

    const selClientName = data?.clientname;
    const selectedClient = clientsList.find(
      (client) => client.clientname === selClientName
    );
    const clientId = selectedClient ? selectedClient.clientid : null;

    const formattedDate = moment().format("DD-MM-YYYY");

    const pendingpayment = total - amountreceived - amountreceiving;

    const payloadPayment = {
      clientid: clientId,
      clientname: data.clientname,
      totalamount: total.toString(),
      totalstatus: "Receipt",
      receivedamount: data.amountreceiving.toString(),
      receivedstatus: "Invoiced",
      pendingamount: pendingpayment.toString(),
      pendingstatus: "Pending",
      paymentdate: formattedDate,
      paymentmode: data.paymentmode,
      paymentreference: data.paymentreference,
      // paymentmode: "UPI",
      // paymentreference: "7876543562711109",
    };
    // console.log("payload:", payloadPayment);

    const paymenturl = "payment";

    try {
      setIsSaveLoading(true);
      const response = await postApi(paymenturl, payloadPayment);
      if (response?.status_code === 200) {
        notification.success({
          message: response.message,
        });
      } else {
        notification.error({
          message: response.message || "An unexpected error occurred.",
        });
        return;
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
      return;
    } finally {
      setIsSaveLoading(false);
    }

    const payload = {
      bookingdate: formatDateToIST(data.bookingdate),
      clientname: data.clientname,
      passengername: data.passengername,
      noofpeople: data.noofpeople.toString(),
      traveldate: formatDateToIST(data.traveldate),
      sector: data.sector,
      pnr: data.pnr,
      airlines: data.airlines,
      flightnumber: data.flightnumber,
      departuretime: formatTimeToIST(data.departuretime),
      arraivaltime: formatTimeToIST(data.arraivaltime),
      fare: fare.toString(),
      fee: fee.toString(),
      gst: gst.toString(),
      total: total.toString(),
      bookinggivenby: data.bookinggivenby,
      ticketissuedby: data.ticketissuedby,
      bookingportal: data.bookingportal,
      totalreceiptstatus: "receipt",
      amountreceived: totalAmountReceived.toString(),
      amountreceivedstatus: "invoiced",
      amountpending: amountpending.toString(),
      amountpendingstatus: "pending",
      ticketstatus: ticketstatus,
    };

    // console.log("Payload to send:", payload);
    const url = isEdit ? `booking/${currentId}` : "booking";
    try {
      setIsSaveLoading(true);
      const apiMethod = isEdit ? putApi : postApi;
      const response = await apiMethod(url, payload);
      if (response?.status_code === 200) {
        notification.success({
          message: response.message,
        });
        const url = "booking";
        getBookingsList(url);
        setIsClientForm(false);
        setIsEdit(false);
        setIsSaveLoading(false);
      } else if (response?.status_code === 404) {
        notification.warning({
          message: "Booking Not Found...",
        });
      } else {
        notification.error({
          message: response.message || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    } finally {
      setIsSaveLoading(false);
    }
  };

  const handleNameClick = (record) => {
    // console.log("Resetting form with record data:", record);
    setIsClientForm(true);
    setCurrentId(record.bookingid);
    setIsEdit(true);
    reset({
      bookingdate: record.bookingdate ? moment(record.bookingdate) : null,
      clientname: record.clientname,
      passengername: record.passengername,
      noofpeople: record.noofpeople.toString(),
      traveldate: record.traveldate ? moment(record.traveldate) : null,
      sector: record.sector,
      pnr: record.pnr,
      airlines: record.airlines,
      flightnumber: record.flightnumber,
      departuretime: record.departuretime
        ? moment.utc(record.departuretime, "HH:mm:ss")
        : null,
      arraivaltime: record.arraivaltime
        ? moment.utc(record.arraivaltime, "HH:mm:ss")
        : null,
      fare: record.fare.toString(),
      fee: record.fee.toString(),
      gst: record.gst.toString(),
      bookinggivenby: record.bookinggivenby,
      ticketissuedby: record.ticketissuedby,
      bookingportal: record.bookingportal,
      amountreceived: record.amountreceived,
      amountpending: record.amountpending,
    });
  };

  const formFields = [
    {
      name: "bookingdate",
      label: "Booking Date",
      type: "date",
      icon: AiOutlineCalendar,
    },
    {
      name: "clientname",
      label: "Client Name",
      type: "select",
      icon: AiOutlineUser,
      options: clientOptions,
    },
    {
      name: "passengername",
      label: "Passenger name",
      type: "input",
      icon: AiOutlineUser,
      editable: true,
    },
    {
      name: "noofpeople",
      label: "Number of People",
      type: "input",
      icon: AiOutlineTeam,
      editable: true,
    },
    {
      name: "traveldate",
      label: "Travel Date",
      type: "date",
      icon: AiOutlineCalendar,
    },
    {
      name: "sector",
      label: "Sector",
      type: "input",
      icon: AiOutlineFileText,
      editable: true,
    },
    {
      name: "pnr",
      label: "PNR",
      type: "input",
      icon: AiOutlineIdcard,
      editable: true,
    },
    {
      name: "airlines",
      label: "Airlines",
      type: "input",
      icon: AiOutlineFileText,
      editable: true,
    },
    {
      name: "flightnumber",
      label: "Flight Number",
      type: "input",
      icon: AiOutlineNumber,
      editable: true,
    },
    {
      name: "departuretime",
      label: "Departure Time",
      type: "time",
      icon: AiOutlineClockCircle,
    },
    {
      name: "arraivaltime",
      label: "Arrival Time",
      type: "time",
      icon: AiOutlineClockCircle,
    },
    {
      name: "fare",
      label: "Fare",
      type: "input",
      icon: BiRupee,
      editable: true,
    },
    {
      name: "fee",
      label: "Fee",
      type: "input",
      icon: BiRupee,
      editable: true,
    },
    {
      name: "gst",
      label: "GST",
      type: "input",
      icon: AiOutlineTag,
      editable: true,
    },
    {
      name: "bookinggivenby",
      label: "Booking Given By",
      type: "input",
      icon: AiOutlineUser,
      editable: true,
    },
    {
      name: "ticketissuedby",
      label: "Ticket Issued By",
      type: "input",
      icon: AiOutlineUser,
      editable: true,
    },
    {
      name: "bookingportal",
      label: "Booking Portal",
      type: "input",
      icon: AiOutlineFileText,
      editable: true,
    },
    {
      name: "amountreceived",
      label: "Amount Received",
      type: "input",
      icon: BiRupee,
      editable: false,
    },
    {
      name: "amountpending",
      label: "Amount Pending",
      type: "input",
      icon: BiRupee,
      editable: false,
    },
    {
      name: "amountreceiving",
      label: "Amount Receiving",
      type: "input",
      icon: BiRupee,
      editable: true,
    },
    {
      name: "paymentmode",
      label: "Payment Mode",
      type: "input",
      icon: MdPayment,
      editable: true,
    },
    {
      name: "paymentreference",
      label: "Payment Reference",
      type: "input",
      icon: MdOutlineReceipt,
      editable: true,
    },
  ];

  const [bookingFilterForm] = Form.useForm();
  const [bookingDateValue, setBookingDateValue] = useState(null);

  const handleBookingDateChange = (date, dateString) => {
    // Check if the date is valid
    if (date && date.isValid()) {
      setBookingDateValue(dateString);
    } else {
      console.warn("Invalid date selected");
    }
  };

  const handleFilterSubmit = (values) => {
    // console.log("Form values before submission:", values);
    const columns = [];
    const valuesArray = [];

    // Check and collect non-empty values
    if (values.clientname) {
      columns.push("clientname");
      valuesArray.push(values.clientname);
    }
    if (values.bookingid) {
      columns.push("bookingid");
      valuesArray.push(values.bookingid);
    }
    if (values.bookingdate) {
      columns.push("bookingdate");
      valuesArray.push(bookingDateValue);
    }

    if (columns.length > 0 && valuesArray.length > 0) {
      const columnsString = columns.join(",");
      const valuesString = valuesArray.join(",");
      const url = `filterbooking?columns=${encodeURIComponent(
        columnsString
      )}&values=${encodeURIComponent(valuesString)}`;

      // console.log("Generated URL:", url);
      getBookingsList(url);
    } else {
      const url = "booking";
      // console.log("URL without params:", url);
      getBookingsList(url);
    }
  };

  const handleReset = () => {
    bookingFilterForm.resetFields();
    const url = "booking";
    getBookingsList(url);
  };

  return (
    <>
      {isClientForm ? (
        <div className="flex space-x-6 p-2" style={{ height: "100vh" }}>
          <div className="flex-1">
            {/* Sticky header */}
            <div className="p-3 flex items-center justify-between sticky top-0 z-10 bg-white">
              <h2 className="com-heading">{`${
                isEdit ? "Edit" : "Create"
              } Ticketing Form`}</h2>
              <Button className="cus-btn" onClick={handleDiscard}>
                Discard
              </Button>
            </div>

            {/* Scrollable content */}
            <div
              className="mt-2 p-3 overflow-y-auto bg-[#f8f8f8]"
              style={{ maxHeight: "calc(100vh - 80px)" }}
            >
              <MultiFieldFormComponent
                formFields={formFields}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                buttonLoading={isSaveLoading}
                buttonName={"Save"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex p-2" style={{ height: "100vh" }}>
          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Sticky Header */}
            <div className="py-3 px-1 flex items-center justify-between rounded-md sticky top-0 z-10 bg-white">
              <h2 className="com-heading">Ticketing List</h2>
              <Button className="cus-btn" onClick={handleAddClick}>
                Add
              </Button>
            </div>

            <Form
              form={bookingFilterForm}
              layout="inline"
              onFinish={handleFilterSubmit}
              style={{
                marginBottom: "20px",
                padding: "20px",
                border: "1px solid #f0f0f0",
                borderRadius: "8px",
                backgroundColor: "#fafafa",
              }}
            >
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    // label="Booking ID"
                    name="bookingid"
                  >
                    <Input
                      placeholder="Enter Booking ID"
                      style={{ width: "150px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    // label="Client Name"
                    name="clientname"
                  >
                    <Input
                      placeholder="Enter Client Name"
                      style={{ width: "150px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    // label="Booking Date"
                    name="bookingdate"
                  >
                    <DatePicker
                      value={
                        bookingDateValue
                          ? moment(bookingDateValue, "YYYY-MM-DD")
                          : null
                      }
                      onChange={handleBookingDateChange}
                      placeholder="Select Booking date"
                      format="YYYY-MM-DD"
                      style={{ width: "180px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="cus-btn"
                      icon={<FilterOutlined className="mr-1" />}
                    >
                      Filter
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button onClick={handleReset} icon={<FilterOutlined />}>
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            {/* Scrollable Table */}
            <div
              className="overflow-y-auto"
              style={{ maxHeight: "calc(100vh - 80px)" }}
            >
              <Table
                dataSource={bookingList}
                columns={columns}
                scroll={{ x: "max-content" }}
                pagination={false}
                onRow={(record) => ({
                  onClick: () => handleNameClick(record),
                })}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Ticketing;

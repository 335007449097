import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import logo from "../../assests/aruvi_newlogo.png";
import "./Login.css";
import { postApi } from "../../utils/postApiMethod";
import { notification } from "antd";

const schema = yup
  .object({
    username: yup.string().required("username is required"),
    password: yup.string().required("password is required"),
  })
  .required();

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const payload = {
      username: data.username,
      password: data.password,
    };
    // console.log("payload", payload);
    const url = "login";
    try {
      const response = await postApi(url, payload);

      if (response?.status_code === 200) {
        notification.success({
          message: response.message,
        });
        navigate("/app/home");
        // console.log("response", response.username);
        localStorage.setItem("username", response.username);
      } else if (response?.status_code === 404) {
        notification.warning({
          message: "User Not Found...",
        });
      } else {
        notification.error({
          message: response.message || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: "Something went wrong. Please try again.",
      });
    }

    // navigate("/app/home");
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-logo">
          <img src={logo} alt="Company Logo" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label htmlFor="username" className="form-label">
              username
            </label>
            <input
              id="username"
              className="form-input"
              {...register("username")}
              placeholder="username"
            />
            <p className="error-message">{errors.username?.message}</p>
          </div>
          <div className="mb-4 relative">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              id="password"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              type={showPassword ? "text" : "password"}
              {...register("password")}
              placeholder="Password"
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <AiOutlineEyeInvisible className="text-gray-500 mt-6" />
              ) : (
                <AiOutlineEye className="text-gray-500 mt-6" />
              )}
            </div>
            <p className="text-red-500 text-xs mt-1">
              {errors.password?.message}
            </p>
          </div>
          <button type="submit" className="login-button">
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
